/** @type {import('tailwindcss').Config} */
const plugin = require('tailwindcss/plugin')

module.exports = {
  content: [
    './components/**/*.{js,vue}',
    './layouts/**/*.vue',
    './pages/**/*.vue',
    './plugins/**/*.js',
    './nuxt.config.js',
  ],
  theme: {
    // overwrite theme
    screens: {
      xs: '448px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1920px',
      '4xl': '2368px',

      narrow: { raw: '(max-height: 663px)' },
      tall: { raw: '(min-height: 660px)' },
    },
    fontSize: {
      xs: ['0.75rem', '1.42857143'], // 12px / 20
      sm: ['0.875rem', '1.42857143'], // 14px / 20
      base: ['1rem', '1.375'], // 16px / 22
      md: ['1.125rem', '1.44444444'], // 18px / 26
      lg: ['1.25rem', '1.75'], // 20px / 28
      xl: ['1.375rem', '1.16666667'], // 22px / 36
      '2xl': ['1.5rem', '1.5'], // 24px / 36
      '3xl': ['1.75rem', '1.14285714'], // 28px / 32
      '4xl': ['2rem', '1.25'], // 32px / 40
      '5xl': ['2.5rem', '1.125'], // 40px / 45
      '6xl': ['3.4375rem', '1.09090909'], // 55px / 60
    },
    colors: {
      page: "var(--page-color, theme('colors.red.DEFAULT'))",
      'page-hover': "var(--page-hover, theme('colors.red.dark'))",

      transparent: 'transparent',
      inherit: 'inherit',
      black: '#000000',
      white: '#FFFFFF',
      'current-color': 'currentColor',
      cream: '#F5F0E3',
      gray: {
        DEFAULT: '#FAF8F6',
        'default-hover': '#FBFBFA',
        'semi-dark': '#EFEFEF',
        dark: '#CFD2CF',
        darker: '#7F8487',
        darkest: '#3B3B3B',
      },

      blue: {
        DEFAULT: '#00A5DD',
        light: '#139CD1',
        dark: '#00447E',
        darker: '#003769',
      },
      turquoise: {
        DEFAULT: '#35B6B4',
        dark: '#24AAA8',
      },
      violet: {
        DEFAULT: '#5a2247',
        dark: '#531E41',
      },
      red: {
        DEFAULT: '#CD0039',
        dark: '#B90839',
      },
    },
    fontFamily: {
      sans: ['Inter', 'sans-serif', 'system-ui'],
    },
    // extend theme
    extend: {
      maxWidth: {
        title: '60ch',
        text: '80ch',
      },
      borderRadius: {
        sm: '0.125rem', // 2px
        DEFAULT: '0.25rem', // 4px
      },
      zIndex: {
        '-1': '-1',
        top: '1000',
      },
      spacing: {
        13: '3.25rem',
        15: '3.75rem',
        17: '4.25rem',
        18: '4.5rem',
        22: '5.5rem',
        23: '5.75rem',
        26: '6.5rem',
      },
      aspectRatio: {
        '1/2': '1 / 2',
        '2/1': '2 / 1',
        '3/4': '3 / 4',
        '4/3': '4 / 3',
        '5/6': '5 / 6',
        '6/7': '6 / 7',
      },
      textUnderlineOffset: {
        5: '5px',
      },
      blur: {
        xs: '2px',
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            color: theme('colors.black'),
            fontSize: theme('fontSize.md') /* 18px */,
            paddingLeft: '0',
            paddingRight: '0',
            h2: {
              color: theme('colors.black'),
              marginTop: '0',
              fontSize: theme('fontSize.3xl') /* 28px */,
              fontWeight: theme('fontWeight.semibold'),
            },
            h3: {
              color: theme('colors.black'),
              marginTop: '0',
              fontSize: theme('fontSize.xl') /* 22px */,
              fontWeight: theme('fontWeight.semiblod'),
            },
            p: {
              color: theme('colors.black'),
              '&+h2': {
                marginTop: '4.5rem',
              },
              '&+h3': {
                marginTop: '4rem',
              },
            },
            strong: {
              color: 'inherit',
            },
            a: {
              color: theme('colors.page'),
              'text-decoration': 'none',
              'overflow-wrap': 'break-word',
              '&:hover': {
                color: theme('colors.page-hover'),
              },
            },
            ul: {
              '&+h2': {
                marginTop: '4.5rem',
              },
              '&+h3': {
                marginTop: '4rem',
              },
              marginLeft: '0',
              listStyleType: "'‒'", // \2013
              paddingLeft: '0.75rem',
              li: {
                paddingLeft: '0.75rem',
                '&::marker': {
                  color: theme('colors.black'),
                },
              },
            },
            ol: {
              '&+h2': {
                marginTop: '4.5rem',
              },
              '&+h3': {
                marginTop: '4rem',
              },
              marginLeft: '0',
              listStyleType: 'decimal',
              paddingLeft: '1.375rem',
              li: {
                paddingLeft: '0.2rem',
                '&:has(p strong)::marker': {
                  fontWeight: theme('fontWeight.black'),
                },
                '&::marker': {
                  color: theme('colors.black'),
                  fontWeight: 'inherit',
                },
              },
            },
            tr: {
              borderBottom: '1px solid black',
              td: {
                p: {
                  hyphens: 'none',
                },
              },
            },
            table: {
              width: '100%',
            },
          },
        },
        sm: {
          css: {
            color: theme('colors.black'),
            fontSize: theme('fontSize.base') /* 16px */,
            paddingLeft: '1.25rem',
            paddingRight: '1.25rem',
            h2: {
              color: theme('colors.black'),
              marginTop: '0',
              fontSize: theme('fontSize.xl') /* 22px */,
              fontWeight: theme('fontWeight.medium'),
            },
            h3: {
              color: theme('colors.black'),
              marginTop: '0',
              fontSize: theme('fontSize.md') /* 18px */,
              fontWeight: theme('fontWeight.medium'),
            },
            p: {
              color: theme('colors.black'),
              '&+h2': {
                marginTop: '3rem',
              },
              '&+h3': {
                marginTop: '2.5rem',
              },
            },
            strong: {
              color: 'inherit',
            },
            a: {
              color: theme('colors.page'),
              'text-decoration': 'none',
              'overflow-wrap': 'break-word',
              '&:hover': {
                color: theme('colors.page-hover'),
              },
            },
            ul: {
              '&+h2': {
                marginTop: '3rem',
              },
              '&+h3': {
                marginTop: '2.5rem',
              },
              marginLeft: '0',
              listStyleType: "'‒'", // \2013
              paddingLeft: '0.625rem',
              li: {
                paddingLeft: '0.625rem',
                '&::marker': {
                  color: theme('colors.black'),
                },
              },
            },
            ol: {
              '&+h2': {
                marginTop: '3rem',
              },
              '&+h3': {
                marginTop: '2.5rem',
              },
              marginLeft: '0',
              listStyleType: 'decimal',
              paddingLeft: '1.25rem',
              li: {
                paddingLeft: '0.2rem',
                '&:has(p strong)::marker': {
                  fontWeight: theme('fontWeight.black'),
                },
                '&::marker': {
                  color: theme('colors.black'),
                  fontWeight: 'inherit',
                },
              },
            },
            tr: {
              borderBottom: '1px solid black',
              td: {
                p: {
                  hyphens: 'auto',
                },
              },
            },
            table: {
              width: '100%',
            },
          },
        },
        xs: {
          css: {
            color: theme('colors.black'),
            fontSize: theme('fontSize.sm') /* 14px */,
            h2: {
              color: theme('colors.black'),
              marginTop: '0',
              fontSize: theme('fontSize.xl') /* 22px */,
              fontWeight: theme('fontWeight.medium'),
            },
            h3: {
              color: theme('colors.black'),
              marginTop: '0',
              fontSize: theme('fontSize.md') /* 18px */,
              fontWeight: theme('fontWeight.medium'),
            },
            p: {
              color: theme('colors.black'),
              marginTop: theme('spacing.3'),
              '&+h2': {
                marginTop: '3rem',
              },
              '&+h3': {
                marginTop: '2.5rem',
              },
            },
            strong: {
              color: 'inherit',
            },
            a: {
              color: theme('colors.page'),
              'text-decoration': 'none',
              'overflow-wrap': 'break-word',
              position: 'relative',
              zIndex: '1',
              '&:hover': {
                color: theme('colors.page-hover'),
              },
            },
            ul: {
              '&+h2': {
                marginTop: '3rem',
              },
              '&+h3': {
                marginTop: '2.5rem',
              },
              marginLeft: '0',
              listStyleType: "'‒'", // \2013
              paddingLeft: '0.625rem',
              li: {
                paddingLeft: '0.625rem',
                '&::marker': {
                  color: theme('colors.black'),
                },
              },
            },
            ol: {
              '&+h2': {
                marginTop: '3rem',
              },
              '&+h3': {
                marginTop: '2.5rem',
              },
              marginLeft: '0',
              listStyleType: 'decimal',
              paddingLeft: '1.25rem',
              li: {
                paddingLeft: '0.2rem',
                '&:has(p strong)::marker': {
                  fontWeight: theme('fontWeight.black'),
                },
                '&::marker': {
                  color: theme('colors.black'),
                  fontWeight: 'inherit',
                },
              },
            },
            tr: {
              borderBottom: '1px solid black',
              td: {
                p: {
                  hyphens: 'auto',
                },
              },
            },
            table: {
              width: '100%',
            },
          },
        },
      }),
    },
  },

  plugins: [
    plugin(function ({ addVariant, e }) {
      addVariant('sidebar', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => {
          return `.sidebar .${e(`sidebar${separator}${className}`)}`
        })
      })

      addVariant('team-page', ({ modifySelectors, separator }) => {
        modifySelectors(({ className }) => {
          return `.team-page .${e(`team-page${separator}${className}`)}`
        })
      })

      addVariant('hover-focus', ['&:hover', '&:focus-within'])
      addVariant('group-hover-focus', [
        '.group:hover &',
        '.group:focus-within &',
      ])
      addVariant('peer-hover-focus', [
        '.peer:hover ~ &',
        '.peer:focus-within ~ &',
      ])
    }),

    function ({ addVariant }) {
      addVariant('has-scrolled', 'nav[data-has-scrolled] &')
    },

    require('@tailwindcss/typography'),
  ],
}
